// Chakra imports
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Collapse,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from 'moment'
import axios from 'axios'
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import { Text } from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"
import { Button, ButtonGroup } from "@chakra-ui/react"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import { Separator } from "components/Separator/Separator";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import { Tag,  TagLabel } from "@chakra-ui/react";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,StatsIcon
} from "components/Icons/Icons.js";
import { Select } from '@chakra-ui/react'

import { AtSignIcon, DownloadIcon, RepeatIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { useLocation} from "react-router-dom"
import { dashboardTableData, timelineData } from "variables/general";
import { useDispatch, useSelector } from "react-redux";
import { handleGetGateways, handleGetGatewaySensorData, handleGetGatewaySensors } from "redux/gateway";
import { chartTimeSeriesDataReducer } from 'lib/reducer';
import LineChart from "components/Charts/LineChart";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { SiEpel } from "react-icons/si";
import {config} from 'env/env'

export default function Reporting(props) {
  const iconBoxInside = useColorModeValue("white", "white");

  const dispatch = useDispatch()

  let domPageLocation = useLocation();

  const loggedIn =  useSelector(state => state.app.loggedIn)
  const accessToken = useSelector(state => state.app.token)
  const orgId = useSelector(state => state.app.userOrgID)
  //const [sensorDataLoaded, setSensorDataLoaded] = useState(false) // 15 minutes

  const [selectedStartDay, setSelectedStartDay]       = React.useState(moment().subtract(7, 'days').format("YYYY-MM-DD"));
  const [selectedEndDay, setSelectedEndDay]           = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedMeasurement, setSelectedMeasurement] = React.useState("emeter");


  const [generateReportLoading, setGenerateReportLoading]     = React.useState(false);

  const [showStartDayPicker, setShowStartDayPicker] = React.useState(false);
  const [showEndDayPicker, setShowEndDayPicker]     = React.useState(false);
  const [showMeasurementPicker, setShowMeasurementPicker]     = React.useState(false);

  const headerStart = "Select Report Start Day"

  const disabledDaysForStartCalender = [
    { from: new moment().toDate(), to: new moment().endOf('month').toDate() }
  ];
  const disabledDaysForEndCalender = [
    { from: new moment().add("days", 1).toDate(), to: new moment().endOf('month').toDate() }
  ];


  const getMeasurementTypeName = (sensorType) => {
    let typeName
    switch(sensorType) {
      case 'emeter':
        typeName =  'Electricity'
      break
      case 'wmeter':
        typeName =  'Water'
      break
    }
    return typeName
  }

  const generateReport = async () => {

    setGenerateReportLoading(true);

    let url = config.apiurl + "/reporting/"+selectedMeasurement+"?org_id="+orgId+
      "&date_start="+moment(selectedStartDay).format("YYYY-MM-DDTHH:mm:ss[Z]")+
      "&date_end="+moment(selectedEndDay).format("YYYY-MM-DDTHH:mm:ss[Z]")
    //console.log("pre-AAAAAAAA")

    try {
      //console.log("initiate")
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer '+ accessToken,
        },
      })
      //console.log("AAAAAAAA")
      setGenerateReportLoading(false);
      //let data = response.data?response.data.filter(d => d.total_minutes>0):null
      let data = response.data?response.data:null
      //console.log(data)
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let _link = document.createElement('a')
      _link.href = window.URL.createObjectURL(blob)
      _link.download = 'Report_'+selectedMeasurement+'_"' + moment(selectedStartDay).format("YYYY-MM-DD")
                                  + '_'+moment(selectedEndDay).format("YYYY-MM-DD")+'.xlsx'
      _link.click()

    }
    catch (error) {
      //console.error('Error fetching data:', error)
      setGenerateReportLoading(false);
    }
  }

  useEffect(() => {
    //console.log(selectedStartDay)
    setShowStartDayPicker(false)
  }, [selectedStartDay])

  useEffect(() => {
    //console.log(selectedEndDay)
    setShowEndDayPicker(false)
  }, [selectedEndDay])

  useEffect(() => {
    //console.log(selectedMeasurement)
    setShowMeasurementPicker(false)
  }, [selectedMeasurement])
  return (
    <Flex flexDirection='column' pt={{ base: "100px", md: "75px" }}>

      <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing='24px'>

      </SimpleGrid>

      <Card p="1rem" my={{ sm: "24px", xl: "0px" }} bg="gray.50">
        <CardHeader p="12px 5px" mb="12px">
          <Text fontSize="lg" color={"black"} fontWeight="bold">
            Excel Report
          </Text>
        </CardHeader>
        <CardBody p="0px 5px">

          <Card p="1rem" my={{ sm: "24px", xl: "0px" }} w={"25%"}>
            <CardHeader p="12px 5px" mb="12px">

              <Flex>
                <Box p="4" >
                  <Text fontSize="lg" color={"black"} fontWeight="bold" >
                    Report Start Date:
                  </Text>
                </Box>
                <Box p="4">
                  <Tag  variantColor="teal" cursor={"pointer"} _hover={{
                    background: "gray.200",
                    color: "black"
                  }} onClick={() => setShowStartDayPicker(true)}>
                    <TagLabel>{selectedStartDay}</TagLabel>
                  </Tag>
                </Box>
              </Flex>

            </CardHeader>
            <Separator />

              <Modal isOpen={showStartDayPicker} onClose={() => setShowStartDayPicker(false)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Select Start Day</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <DayPicker
                      mode="single"
                      selected={selectedStartDay}
                      disabled={disabledDaysForStartCalender}
                      onSelect={(val) => setSelectedStartDay(moment(val).format("YYYY-MM-DD"))}
                      header={headerStart}
                    />
                  </ModalBody>
                  <ModalFooter>
                  </ModalFooter>
                </ModalContent>
              </Modal>

          </Card>
          <Card style={{marginLeft:30}} p="1rem" my={{ sm: "24px", xl: "0px" }} w={"25%"}>
            <CardHeader p="12px 5px" mb="12px">

              <Flex>
                <Box p="4" >
                  <Text fontSize="lg" color={"black"} fontWeight="bold">
                    Report End Date:
                  </Text>
                </Box>
                <Box p="4">
                  <Tag variantColor="teal" cursor={"pointer"} _hover={{
                    background: "gray.200",
                    color: "black"
                  }}  onClick={() => setShowEndDayPicker(true)}>
                    <TagLabel>{selectedEndDay}</TagLabel>
                  </Tag>
                </Box>
              </Flex>
            </CardHeader>
            <Separator />


            <Modal isOpen={showEndDayPicker} onClose={() => setShowEndDayPicker(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Select End Day</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <DayPicker
                    mode="single"
                    backgroundColor={"gray.100"}
                    selected={selectedEndDay}
                    disabled={disabledDaysForEndCalender}
                    onSelect={(val) => setSelectedEndDay(moment(val).format("YYYY-MM-DD")) }
                    header={headerStart}
                  />
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Card>
          <Card style={{marginLeft:30}} p="1rem" my={{ sm: "24px", xl: "0px" }} w={"25%"}>
            <CardHeader p="12px 5px" mb="12px">

              <Flex>
                <Box p="4" >
                  <Text fontSize="lg" color={"black"} fontWeight="bold">
                    Measurement:
                  </Text>
                </Box>
                <Box p="4">
                  <Tag variantColor="teal" cursor={"pointer"} _hover={{
                    background: "gray.200",
                    color: "black"
                  }}  onClick={() => setShowMeasurementPicker(true)}>
                    <TagLabel>{getMeasurementTypeName(selectedMeasurement)}</TagLabel>
                  </Tag>
                </Box>
              </Flex>
            </CardHeader>
            <Separator />

            <Modal isOpen={showMeasurementPicker} onClose={() => setShowMeasurementPicker(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Select Measurement</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Select  onChange={(e) => { setSelectedMeasurement(e.target.value) }}>
                    <option selected={selectedMeasurement == 'emeter'} value='emeter'>{getMeasurementTypeName('emeter')}</option>
                    <option selected={selectedMeasurement == 'wmeter'} value='wmeter'>{getMeasurementTypeName('wmeter')}</option>
                  </Select>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
              </ModalContent>
            </Modal>

          </Card>

          <Box style={{marginLeft:30}}>
            <Button isLoading={generateReportLoading} loadingText={"Generating.."} onClick={() => {  generateReport() } } style={{height:130}} rightIcon={<DownloadIcon />} colorScheme="teal" variant="outline">
              Generate Report
            </Button>
          </Box>

        </CardBody>
      </Card>


    </Flex>
  );
}
