
// Takes a an array with a period P like this =>  [[time1, value1], [time2, value2], ...., [time133, value[133]]
// Reduces it into bigger timeframe like 5min to 15 min, 5min to 60min etc..
export const chartTimeSeriesDataReducer = (buffer, groupDataPeriod) => {

  const groupedData = buffer.reduce((acc, curr) => {
    const timestamp = curr[0];
    const roundedTimestamp = new Date(Math.floor(timestamp / (groupDataPeriod * 60 * 1000)) * (groupDataPeriod * 60 * 1000));
    const existingGroup = acc.find(group => group[0].getTime() === roundedTimestamp.getTime());
    if (existingGroup) {
      existingGroup[1] += curr[1];
    } else {
      acc.push([roundedTimestamp, curr[1]]);
    }
    return acc;
  }, []);

  return groupedData;
};
