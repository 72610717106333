// Chakra imports
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from 'moment'
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import BarChart from "components/Charts/BarChart";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,StatsIcon
} from "components/Icons/Icons.js";
import {AtSignIcon, RepeatIcon} from '@chakra-ui/icons'
import React, { useEffect, useState } from "react";
import { useLocation} from "react-router-dom"
import { dashboardTableData, timelineData } from "variables/general";
import { useDispatch, useSelector } from "react-redux";
import { handleGetGateways, handleGetGatewaySensorData, handleGetGatewaySensors } from "redux/gateway";
import { chartTimeSeriesDataReducer } from 'lib/reducer';
import LineChart from "components/Charts/LineChart";
import LocationTable from "./components/LocationTable";

const avg = arr => arr.reduce((acc,v,i,a)=>(acc+v/a.length),0);
const findVariance = (arr = []) => {
  if(!arr.length){
    return 0;
  };
  const sum = arr.reduce((acc, val) => acc + val);
  const { length: num } = arr;
  const median = sum / num;
  let variance = 0;
  arr.forEach(num => {
    variance += ((num - median) * (num - median));
  });
  variance /= num;
  return variance;
};

export default function Dashboard(props) {
  const iconBoxInside = useColorModeValue("white", "white");

  const dispatch = useDispatch()

  let domPageLocation = useLocation();

  const loggedIn =  useSelector(state => state.app.loggedIn)
  const accessToken = useSelector(state => state.app.token)
  const orgId = useSelector(state => state.app.userOrgID)
  const gateways = useSelector(state => state.gateway.gateways)
  const sensors = useSelector(state => state.gateway.sensors)
  const sensorData = useSelector(state => state.gateway.sensor_data)
  //const [sensorDataLoaded, setSensorDataLoaded] = useState(false) // 15 minutes

  const [groupDataPeriod, setGroupDataPeriod] = useState(60) // 15 minutes

  const [unitEnergyPrice, setUnitEnergyPrice] = useState(2.4)
  const [energyType, setEnergyType] = useState("Watt-hour")

  const [temperatureData, setTemperatureData] = useState({ data: [], name: "" } )
  const [temperatureDataInfo, setTemperatureDataInfo] = useState({minVal:0, maxVal:0, avgVal:0, variantVal:0})

  const [energyExtendedData, setEnergyExtendedData] = useState([] )
  const [energyMetric, setEnergyMetric] = useState('wh' )
  const [energyData, setEnergyData] = useState({ data: [], name: "" } )
  const [energyMinMaxDates, setEnergyMinMaxDates] = useState({min:0, max: 0})

  const [deviceInfo, setDeviceInfo] = useState({})
  const [dataSensorMac, setDataSensorMac] = useState("")
/*
  useEffect(() => {
    if (loggedIn) {
      console.log("TRIGGER 1")
      if(orgId == 4) { setUnitEnergyPrice(2.4) ; setEnergyMetric('wh') }
      if(orgId == 5) { setUnitEnergyPrice(4.45); setEnergyType("Kilowatt-hour"); setEnergyMetric('kwh') }
      //      dispatch(handleGetGateways(orgId, ''))
    }
  }, [loggedIn])

  useEffect(()=> {
    if(gateways.results != undefined && gateways.results.length > 0) {
      let _device_info = {}
      _device_info["GatewayName"] = gateways.results[0].name
      _device_info["GatewayMac"] = gateways.results[0].mac

      setDeviceInfo(_device_info)

      console.log("TRIGGER 2")
      dispatch(handleGetGatewaySensors(gateways.results[0].id))
    }
  }, [gateways])

  useEffect(() => {
    if(sensors.results != undefined && sensors.results.length > 0) {

      setDataSensorMac(sensors.results[0].mac)
      let date_end = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      let date_start = moment().utc().subtract(30, 'd').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      console.log("TRIGGER 3")

      dispatch(handleGetGatewaySensorData(sensors.results[0].mac, date_start, date_end))
    }
  }, [sensors])

  useEffect(() => {
    let buffer_energy = []
    let buffer_temperature = []
    let temperature_active = (orgId == 4 ? true : false)

    if(sensorData != undefined) {
      if(sensorData[0] != undefined) {
        let min = sensorData[0].data[0]["read_on_ts"]
        let max = sensorData[0].data.slice(-1)[0]["read_on_ts"]

        setEnergyMinMaxDates({ min: min, max: max })

        let temperature_analysis = [];
        for (var i in sensorData[0].data) {
          let econsumed = sensorData[0].data[i]["energy_consumed"];
          if(orgId == 5) econsumed = Math.round(econsumed / 1000.0)
          buffer_energy.push([sensorData[0].data[i]["read_on_ts"], econsumed])
          if(temperature_active) {
            if (sensorData[0].data[i]["temperature"] > -40) {
              buffer_temperature.push([sensorData[0].data[i]["read_on_ts"], sensorData[0].data[i]["temperature"]])
              temperature_analysis.push(parseFloat(sensorData[0].data[i]["temperature"]))
            }
          }
        }

        // Group data by periods, if necessary
        if(groupDataPeriod !== false) {
          buffer_energy = chartTimeSeriesDataReducer(buffer_energy, groupDataPeriod)
        }

        console.log("TRIGGER 4")
        let energyChartBuffer = { name: sensorData[0]["mac"], data: buffer_energy }

        if(temperature_active) {
          let temperatureChartBuffer = { name: sensorData[0]["mac"], data: buffer_temperature }
          // Calculate temperature Info
          temperatureDataInfo.minVal = Math.min(...temperature_analysis)
          temperatureDataInfo.maxVal = Math.max(...temperature_analysis)
          temperatureDataInfo.avgVal = avg(temperature_analysis).toFixed(1)
          temperatureDataInfo.variantVal = findVariance(temperature_analysis)

          setTemperatureData(temperatureChartBuffer)
        }
        setEnergyData(energyChartBuffer)
        setEnergyExtendedData(  chartTimeSeriesDataReducer(buffer_energy, 24*60)) // Daily information
      }
    }
    console.log("GROUP PERIOD: ", groupDataPeriod)
  }, [sensorData, groupDataPeriod])
*/
  const [ddd, setDdd] = useState([{key:"1",
    name:"Loc 1",
    location_code: "XY230232",
    logo:"logo.png",
    consumption_share:"12.45%",
    status:"online",
    date:"May 1, 2023"}])

  return (
    <Flex flexDirection='column' pt={{ base: "100px", md: "75px" }}>

      <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing='24px'>
        <LocationTable title={"Locations"} captions={["Location Name", "Installation Date", "Consumption Type", "Status"]} data={ddd} />

      </SimpleGrid>

      <Grid
        templateColumns={{ sm: "1fr", lg: (orgId == 4 ?  "1.3fr 1.7fr" :"1fr" ) }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px' mt="20px"
        mb={{ lg: "26px" }}>

      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        gap='24px' my='26px'>
      </Grid>



    </Flex>
  );
}
