import {createAction, handleActions} from 'redux-actions'

import {STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from 'constants'

import { getMPoints } from "lib/api";
import { setGWErr, setGWStatus, setSensors } from "./gateway";

const initialState = {
  status: STATUS_NONE,
  error: '',
  mpoints: []
}

export const setMPoints = createAction('SET_MPOINTS')
export const setMPointStatus = createAction('SET_MPOINT_STATUS')
export const setMPointError = createAction('SET_MPOINT_ERROR')


export default handleActions({
  RESET_MPOINTS: () => (initialState),
  SET_MPOINTS: (state, action) => ({ ...state, mpoints: action.payload }),
  SET_MPOINT_STATUS: (state, action) => ({ ...state, status: action.payload }),
  SET_MPOINT_ERROR: (state, action) => ({...state, error: action.payload}),
}, initialState)



export const handleGetMPoints = (organization_id) => {
  return function (dispatch, getState) {
    const { userOrgID } = getState().app
    dispatch(setMPointError(''))
    dispatch(setMPointStatus(STATUS_LOADING))
    getMPoints(organization_id)
      .then(resp => {
        if (resp.ok) {
          dispatch(setMPoints(resp.data))
          dispatch(setMPointStatus(STATUS_SUCCESS))
        }
        else {
          dispatch(setMPointError(resp.data))
          dispatch(setMPointStatus(STATUS_ERROR))
        }
      })
  }
}
