import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  Img,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { OnlineIcon } from "components/Icons/Icons";
import { NavLink } from "react-router-dom";

function TablesTableRow(props) {
  const { id, logo, defaultImage, name, code, measurementPoint, pointType, pointTypeIcon, status, activation_date, magnifyDefaultImage } = props;

  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>

      <Td minWidth={{ sm: "250px" }} pl="0px">

        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Img onClick={() => magnifyDefaultImage(defaultImage)} src={defaultImage} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <NavLink to={{ pathname: "/portal/emeter",  params:{location_id: id, pointType: pointType, locationName: name, measurementPoint: measurementPoint} }}>
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {code}
            </Text>
            </NavLink>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {activation_date}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {pointTypeIcon}
        </Text>
      </Td>

      <Td>
        <Tooltip hasArrow label={status} placement="top">
        <Badge
          bg={status === "online" ? "green.300" : bgStatus}
          color={status === "online" ? "white" : colorStatus}
          fontSize="22px"
          p="3px 10px"
          borderRadius="8px"
        >
          <OnlineIcon />
        </Badge>
        </Tooltip>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
