import React, {useState, useEffect, useReducer } from "react";
import ReactApexChart from "react-apexcharts";
import { lineChartData, lineChartOptions2 } from "variables/charts";
import { useSafeLayoutEffect } from "@chakra-ui/react";


const LineChart = ({data, startDate, endDate}) => {

  const [chartData , setChartData] = useState({
    options: lineChartOptions2,
    series: [data]
  })

  const [display, setDisplay] = useState(false);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    chartData.series = [data] // ALWAYS ARRAY ALWAYSSSSS
    setChartData(chartData)
    setTimeout(() => forceUpdate(), 100);
  }, [data])

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="area"
      width="100%"
      height="100%"
    />
  );

}

export default LineChart;
