import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import moment from 'moment'
import AuthLayout from "layouts/Auth.js";
import PortalLayout from "./layouts/Portal.js";
//import RTLLayout from "layouts/RTL.js";
import { handleLogout, refreshToken } from 'redux/app'

const unsecured = ['/signup', '/singin', '/reset', '/login']

const App = ({isLocal}) => {
  //if (isLocal) loginComp = Login
  const location = useLocation()
  const dispatch = useDispatch()
  const loc = location.pathname.toLowerCase()
  const loggedIn = useSelector(state => state.app.loggedIn)
  const te = useSelector(state => state.app.tokenExp)
  const tokenStartTime = useSelector(state => state.app.tokenStartTime)

  let interval

  // Login - Logout - JWT control cycle : This cycle refresh the JWT token at every "lifetime" period of JWT token
  // if not, user will be logged out.
  React.useEffect(() => {
    if (!loggedIn) {
      return
    }
    if (loggedIn) {
      //console.log(`initializing interval`);
      interval = setInterval(() => {

          const mte = moment().unix()
          //console.log(tokenStartTime, mte, te, mte-te - tokenStartTime, new Date(tokenStartTime*1000))
          if (tokenStartTime + (te - 10) < mte) {
            //console.log("Refresh Token", mte)
            dispatch(refreshToken())
          }

          if (tokenStartTime + te < mte) {
            //console.log("Automatic Logout", mte)
            dispatch(handleLogout())
          }

      }, 1000);
    }

    return () => clearInterval(interval);

  }, [loggedIn, tokenStartTime, te]); // has no dependency - this will be called on-component-mount


  //(unsecured.indexOf(loc) == -1) &&
  if ( !loggedIn) {
    return (
      <div>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Redirect from={`/`} to="/auth" />
        </Switch>
      </div>
    )
  }

  // USER TYPE 'A BAK, ONA GORE TEKNOPARK DASHBOARD MU, BASKA DASHBOARD MU GOSTER

  // WARNING: This is router below, routes to "LAYOUT" only
  // Make sure you do not route it to a regular route on "routes.js"

  return (
    <div>
      <Switch>
        <Route path={`/portal`} component={PortalLayout} />
        <Redirect from={`/`} to="/portal" />
      </Switch>
    </div>
  )
}

export default App
