// Chakra imports
import { AbsoluteCenter, Flex, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TimelineRow from "components/Tables/TimelineRow";
import React, { useEffect, useReducer, useState } from "react";
import moment from "moment/moment";
import { FaCreditCard } from "react-icons/fa";
import { useSelector } from "react-redux";
import { STATUS_LOADING } from "constants";

const InvoiceOverview = ({ title, unitEnergyPrice, energyMetric, currency, dataMetric }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const [data, setData] = useState([])
  const [totalConsumption, setTotalConsumption] = useState(0)
  const [timelineData, setTimelineData] = useState([])
  const [divider, setDivider] = useState(1)


  const recordStatus = useSelector(state => state.gateway.emeter_history_status)
  const recordLogs = useSelector(state => state.gateway.emeter_history_data)

  useEffect(() => {
    if (recordStatus == STATUS_LOADING) {
      setTimelineData([])
    }
  },[recordStatus])

  useEffect(() => {
    if(recordLogs !== undefined) {
      if (recordLogs.length > 0) {
        let _total = {};

        recordLogs.map(record => {
          let _key = record._id.year + "_" + record._id.month
          if (_total[_key] === undefined) _total[_key] = 0
          _total[_key] += record.total
        })

        setTotalConsumption(_total)

        let _divider = 1
        if (dataMetric == "Wh") _divider = 1000
        if (dataMetric == "kWh") _divider = 1

        if (Object.keys(_total).length > 0) {

          let _timelineData = []

          Object.keys(_total).map(k => {
            let total_wh = _total[k]
            let _price = (total_wh / _divider) * unitEnergyPrice
            _timelineData.push({
              logo: FaCreditCard,
              date: _price.toFixed(2) + " " + currency + "  (" + (total_wh / _divider).toFixed(1) + " kWh)",
              subinfo: " [ Unit Price: " + unitEnergyPrice.toFixed(1) + " " + currency + " ]",
              title: moment(k.split("_")[1], 'M').format('MMMM') + " / " + k.split("_")[0],
              color: "orange.300",
            })
          })
          setDivider(_divider)
          setTimelineData(_timelineData)
        }
      }
    }

  }, [recordLogs])

  return (
    <Card maxH='100%'>
      <CardHeader p='22px 0px 35px 14px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            <Text fontWeight='bold' as='span' color='blue.400'>
              Monthly Invoices
            </Text>{" "}
            in this year.
          </Text>
        </Flex>
      </CardHeader>
      <CardBody ps='20px' pe='0px' mb='31px' position='relative'>
        {recordStatus == STATUS_LOADING ?
          <AbsoluteCenter>
            <Spinner  mt={"0px"}  mb={"10px"}
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='xl'
            /></AbsoluteCenter> : null }
        <Flex direction='column'>
          {timelineData.map((row, index, arr) => {
            return (
              <TimelineRow
                key={row.title}
                logo={row.logo}
                title={row.title}
                date={row.date}
                subinfo={row.subinfo}
                color={row.color}
                index={index}
                arrLength={arr.length}
              />
            );
          })}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default InvoiceOverview;
