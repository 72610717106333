const USER = {
  LOGIN: 'USER_LOGIN',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAIL: 'USER_LOGIN_FAIL',
  SIGN_UP: 'SIGN_UP',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAIL: 'SIGN_UP_FAIL'
}

const ORGANIZATION = {
  CREATE_ORG: 'CREATE_ORG',
  CREATE_ORG_SUCCESS: 'CREATE_ORG_SUCCESS',
  CREATE_ORG_FAIL: 'CREATE_ORG_FAIL',
  CREATE_ORG_RESET:'CREATE_ORG_RESET',
  GET_ORGANIZATION_LIST: 'GET_ORGANIZATION_LIST',
  GET_ORGANIZATION_LIST_SUCCESS: 'GET_ORGANIZATION_LIST_SUCCESS',
  GET_ORGANIZATION_LIST_FAIL: 'GET_ORGANIZATION_LIST_FAIL',
  ORGANIZATION_LIST_RESET:'ORGANIZATION_LIST_RESET',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_FAIL: 'GET_ORGANIZATION_FAIL',
  ORGANIZATION_RESET:'ORGANIZATION_RESET',
  SEND_INVITE:'SEND_INVITE',
  SEND_INVITE_SUCCESS:'SEND_INVITE_SUCCESS',
  SEND_INVITE_FAIL:'SEND_INVITE_FAIL',
  SEND_INVITE_RESET:'SEND_INVITE_RESET'
}

export {USER,ORGANIZATION}
