import { createAction, handleActions } from 'redux-actions'

import {STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from 'constants'
import {createUser, editUser, deleteUser, getUserList} from 'lib/api'

const initialState = {
  status: STATUS_NONE,
  add_status: STATUS_NONE,
  edit_status: STATUS_NONE,
  delete_status: STATUS_NONE,
  error: '',
  users: {},
  accessUserID: 0,
}

export const resetUser = createAction('RESET_USER')
export const setUserErr = createAction('SET_USER_ERR')
export const setUsers = createAction('SET_USERS')
export const setUserStatus = createAction('SET_USER_STATUS')
export const setUserAddStatus = createAction('SET_USER_ADD_STATUS')
export const setUserEditStatus = createAction('SET_USER_EDIT_STATUS')
export const setUserDeleteStatus = createAction('SET_USER_DELETE_STATUS')
export const setAccessUserID = createAction('SET_ACCESS_USER_ID')

export default handleActions({
  RESET_USER: () => (initialState),
  SET_USER_ERR: (state, action) => ({ ...state, error: action.payload }),
  SET_USERS: (state, action) => ({ ...state, users: action.payload }),
  SET_USER_STATUS: (state, action) => ({ ...state, status: action.payload }),
  SET_USER_ADD_STATUS: (state, action) => ({ ...state, add_status: action.payload }),
  SET_USER_EDIT_STATUS: (state, action) => ({ ...state, edit_status: action.payload }),
  SET_USER_DELETE_STATUS: (state, action) => ({ ...state, delete_status: action.payload }),
  SET_ACCESS_USER_ID: (state, action) => ({ ...state, accessUserID: action.payload }),
}, initialState)

export const loadUsers = (off, lim, orgid, search) => {
  return function(dispatch, getState) {
    //dispatch(handleGetOrgList(0, 10000))
    dispatch(setUserErr(''))
    dispatch(setUserStatus(STATUS_LOADING))
    let p = {offset: off, limit: lim,search:search}
    if (orgid > 0) {
      p.organization_id = orgid
    }
    getUserList(p, getState().app.userRole)
      .then(resp => {
        if (resp.ok) {
          dispatch(setUsers(resp.data))
          dispatch(setUserStatus(STATUS_SUCCESS))
        }
        else {
          dispatch(setUserStatus(STATUS_ERROR))
          dispatch(setUserErr(resp.data))
        }
      })
  }
}

