import {create} from 'apisauce'

const epLogin = '/user/login/'
const epTokenRefresh = 'token/refresh/'
const epOrganizations = '/organizations/'
const epGateways = '/gateways/'
const epSensors = '/sensors/'
const epMPoints = '/mpoints/'
const epSensorTypes = '/sensor_types/'
const epSensorData = '/sensor_data/'
const epLocations = '/locations/'
const epEmeterConsumptionHistory = '/emeter/consumption/history/daily/'

let api = null
let authorized = false
let baseURL = ''
let token = ''

const configureAPI = () => {
  if (baseURL == '') return
  const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  api = create({baseURL: baseURL, headers: header})
  if (token != '') {
    apiSetToken(token)
  }
}

export const apiSetBaseURL = (url) => {
  baseURL = url
  token = ''
  configureAPI()
}

export const apiSetToken = (tok) => {

  token = tok
  if (tok && tok !== '') {
    api.setHeader('Authorization', 'Bearer ' + tok)
    authorized = true
    return
  }
  api.setHeader('Authorization', '')
}

export const isAuthenticated = () => {
  return authorized
}

export const unauth = (orgid, idle) => {
  //const typ = (idle) ? actPatLogoutIdle : actLogout
  //logAccess(orgid, typ)
  apiSetToken('')
}

export const auth = (email, pass) => {
  return api.post(epLogin, {username: email, password: pass})
    .then(resp => {
      if (resp.ok) {
        //console.log(resp.data.access)
        apiSetToken(resp.data.access)
      }
      return resp
    })
}

export const refreshTok = (refreshToken) => {
  return api.post(epTokenRefresh, {refresh: refreshToken})
    .then(resp => {
      if (resp.ok) {
        //console.log("Refreshing..")
        apiSetToken(resp.data.access)
      }
      return resp
    })
}


export const getOrganization = (id) => {
  return api.post(epOrganizations, {org_id: id})
}

export const getLocations = (payload) => {
    return api.get(epLocations, payload)
}


export const getUserList = (payload, userRole) => {
  if (userRole === ROLES.SUPER_ADMIN) {
    return api.get(epUserList, payload)
  }
  else {
    return api.get(epOrgUserList, payload)
  }
}

// Get Measurement Points
export const getMPoints = (organization_id) => {
  let ep = epMPoints

  return api.get(ep, {org_id: organization_id})
}

export const getGatewayList = (payload) => {
    return api.post(epGateways, payload)
}

export const getGateways = (organization_id, location_id, search) => {
  let ep = epGateways
  if (search && search.trim().length > 0) {
    ep += '&search=' + search
  }
  return api.get(ep, {org_id: organization_id, location_id: location_id})
}

export const getSensors = (gateid, orgid) => {
  return api.get(epSensors, {gateway_id: gateid})
}

export const getSensorTypes = (orgid) => {
  return api.get(epSensorTypes, {org_id: orgid})
}

export const getSensorData = (org_id, mpoint_id, date_start, date_end) => {
  return api.post(epSensorData, {org_id: org_id, mpoint_id: mpoint_id, date_start:date_start, date_end:date_end})
}

export const getEmeterConsumptionHistory = (org_id, mpoint_id, date_start, date_end) => {
  return api.post(epEmeterConsumptionHistory, {org_id: org_id, mpoint_id: mpoint_id, date_start:date_start, date_end:date_end})
}

