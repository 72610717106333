import { applyMiddleware, createStore, compose, combineReducers } from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import app from './app'
import user from './user'
import gateway from './gateway'
import locations from './locations'
import mpoints from './measurement_points'

export const configureStore = (env) => {
  const pr = persistReducer({key: 'root', storage}, combineReducers({
    app,
    user,
    gateway,
    locations,
    mpoints
  }))
  const middleware = [ thunk ]

  const con = {}
  if (env !== 'production') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    con.store = createStore(pr, composeEnhancers(applyMiddleware(...middleware)))
  }
  else {
    con.store = createStore(pr, applyMiddleware(...middleware))
  }
  con.persistor = persistStore(con.store)
  return con
}

