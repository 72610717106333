import {USER,ORGANIZATION} from './actionTypes'

export const STATUS_NONE = 0
export const STATUS_LOADING = 1
export const STATUS_ERROR = 2
export const STATUS_SUCCESS = 3


export const ROLES = {
  SUPER_ADMIN:'superuser',
  ADMIN:'admin',
  USER:'user',
  PATIENT: 'patient',
  FAMILY: 'family member',
  INSTALLER: 'installer',
}
