import { createAction, handleActions } from 'redux-actions'
import {ROLES, ROLEIDS} from 'constants'
import { STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'constants'
import {refreshTok, apiSetToken, auth, unauth} from 'lib/api'
import { resetUser, setUserStatus } from "./user";
import {resetGateways} from './gateway'
import moment from 'moment'
import { create } from "apisauce";

const initialState = {
  loggedIn: false,
  loginStatus: STATUS_NONE,
  userRole: ROLES.USER,
  userOrgID: 0,
  loggedInUser: {},
  loginErr: '',
  token: '',
  refreshToken: '',
  tokenExp: 0,
  tokenStartTime:0,
  signupErr: '',
  resetErr: '',
  resetStatus: STATUS_NONE,
  changePassErr: '',
  changePassStatus: STATUS_NONE,
  refreshTimer: 0,
  userLogo: '',
}

export const setLoginStatus = createAction('SET_LOGIN_STATUS')
export const setLoggedIn = createAction('SET_LOGGED_IN')
export const setLoggedInUser = createAction('SET_LOGGED_IN_USER')
export const setLoginErr = createAction('SET_LOGIN_ERR')
export const setLoginToken = createAction('SET_LOGIN_TOKEN')
export const setRefreshToken = createAction('SET_REFRESH_TOKEN')
export const setUserRole = createAction('SET_USER_ROLE')
export const setSignupErr = createAction('SET_SIGNUP_ERR')
export const resetApp = createAction('RESET_APP')
export const setResetErr = createAction('SET_RESET_ERR')
export const setResetStatus = createAction('SET_RESET_STATUS')
export const setUserOrgID = createAction('SET_APP_ORGID')
export const setChangePassStatus = createAction('SET_CHANGEPASS_STATUS')
export const setChangePassErr = createAction('SET_CHANGEPASS_ERROR')
export const setRefreshTimer = createAction('SET_REFRESH_TIMER')
export const setTokExp = createAction('SET_TOKEN_EXPIRE')

export const setTokStart = createAction('SET_TOKEN_STARTTIME')

export const setUserLogo = createAction('SET_USER_LOGO')

export default handleActions({
  RESET_APP: () => (initialState),
  SET_LOGIN_STATUS: (state, action) => ({...state, loginStatus: action.payload}),
  SET_LOGGED_IN: (state, action) => ({ ...state, loggedIn: action.payload }),
  SET_LOGGED_IN_USER: (state, action) => ({ ...state, loggedInUser: action.payload }),
  SET_LOGIN_ERR: (state, action) => ({ ...state, loginErr: action.payload }),
  SET_LOGIN_TOKEN: (state, action) => ({ ...state, token: action.payload }),
  SET_REFRESH_TOKEN: (state, action) => ({ ...state, refreshToken: action.payload }),
  SET_USER_ROLE: (state, action) => ({ ...state, userRole: action.payload }),
  SET_SIGNUP_ERR: (state, action) => ({ ...state, signupErr: action.payload }),
  SET_RESET_ERR: (state, action) => ({ ...state, resetErr: action.payload }),
  SET_RESET_STATUS: (state, action) => ({ ...state, resetStatus: action.payload }),
  SET_APP_ORGID: (state, action) => ({ ...state, userOrgID: action.payload }),
  SET_CHANGEPASS_STATUS: (state, action) => ({ ...state, changePassStatus: action.payload }),
  SET_CHANGEPASS_ERROR: (state, action) => ({ ...state, changePassErr: action.payload }),
  SET_REFRESH_TIMER: (state, action) => ({ ...state, refreshTimer: action.payload }),
  SET_TOKEN_EXPIRE: (state, action) => ({ ...state, tokenExp: action.payload }),
  SET_TOKEN_STARTTIME: (state, action) => ({ ...state, tokenStartTime: action.payload }),
  SET_USER_LOGO: (state, action) => ({...state, userLogo: action.payload})
}, initialState)


export const isTokValid = ()  => {
  return function(dispatch, getState) {
    const {tokenExp, loggedIn} = getState().app
    let te = tokenExp
    if (!tokenExp) te = 0
    if (!loggedIn) return
    const now = moment().unix()
    let inv = (te < now)
    if (inv) {
      dispatch(handleLogout())
    }
  }
}

export const handleLogin = (email, pass, hist, t)  => {
  return function(dispatch, getState) {
    dispatch(setLoginErr(''))
    dispatch(setLoginStatus(STATUS_LOADING))
    auth(email, pass)
      .then(resp => {
        //t.setState({loadingIcon: false})
        if (resp.ok) {
          //t.openNotification('Login Successful','Login successful. Please wait to route.',true)
          const loggedInUser = resp.data
          apiSetToken(loggedInUser.access)
          //const {role,orgid} = getRoleOrg(loggedInUser.roles)
          //dispatch(setUserRole(role))
          dispatch(setUserOrgID(loggedInUser.organization_id))
          dispatch(setUserLogo(loggedInUser.organization_logo))
          /*
          if (role == ROLES.ADMIN) {
            dispatch(handleGetOrg(orgid))
            dispatch(handleGetOrgPendingInvites(orgid))
          }
          if (role == ROLES.SUPER_ADMIN) {
            dispatch(handleGetOrgList())
          }*/
          dispatch(setLoggedInUser(loggedInUser))
          dispatch(setLoginToken(loggedInUser.access))
          dispatch(setRefreshToken(loggedInUser.refresh))

          dispatch(setTokStart(moment().unix()))
          dispatch(setTokExp(loggedInUser.lifetime))

          dispatch(setLoggedIn(true))
          dispatch(setLoginStatus(STATUS_SUCCESS))
          //hist.push('/')

        }
        else {
          //resp.status === 401 ?
          dispatch(setLoginErr(resp.data))
          dispatch(setLoginStatus(STATUS_ERROR))
        }
      })
  }
}

export const refreshToken = () => {
  return function(dispatch, getState) {
      const { refreshToken } = getState().app
      refreshTok(refreshToken)
        .then(resp => {
          if (resp.ok) {
            //console.log("REFRESH TOKEN RUN", resp.data)
            dispatch(setTokStart(moment().unix()))
            dispatch(setTokExp(resp.data.lifetime))
            dispatch(setRefreshToken(resp.data.refresh))
            dispatch(setLoginToken(resp.data.access))
            //setLoginToken(refresh, tm)
          }
          else {
            dispatch(handleLogout)
          }
        })
  }
}

export const handleLogout = (idle)  => {
  return function(dispatch, getState) {
    const { refreshTimer, userOrgID } = getState().app

    if (refreshTimer > 0) {
      clearTimeout(refreshTimer)
      dispatch(setRefreshTimer(initialState.refreshTimer))
    }
    unauth(userOrgID, idle)
    dispatch(resetApp())
    dispatch(resetUser())
    //dispatch(resetOrg())
    dispatch(resetGateways())
    dispatch(setLoggedIn(false))
  }
}
