/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import {apiSetToken, apiSetBaseURL} from 'lib/api'
import {configureStore} from 'redux/store'
import {PersistGate} from 'redux-persist/integration/react'
import {config} from 'env/env'

import App from './App'

apiSetBaseURL(config.apiurl)

const con = configureStore(config.reduxenv)

const onBeforeLift = () => {
  const {token} = con.store.getState().app
  apiSetToken(token)
}

ReactDOM.render(
  <Provider store={con.store}>
    <PersistGate
      loading={null}
      onBeforeLift={onBeforeLift}
      persistor={con.persistor}
    >
    <HashRouter>
      <Switch>
        <App></App>
      </Switch>
    </HashRouter>
    </PersistGate>
</Provider>,  document.getElementById("root") );
