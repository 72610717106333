
///*
export const config = {
	name: 'development',
	webUrl: 'https://dev.atlasec.co/',
	apiurl: 'https://apidev.atlasec.co/api/v1',
	imgBaseUrl: 'https://apidev.atlasec.co/',
	reduxenv: 'development',
	websocketUrl: 'wss://ws1.atlasec.co/api/v1/ws',
	hubVersion: ''
}//*/

/*
export const config = {
	name: 'local',
	webUrl: 'http://localhost:8000/',
	apiurl: 'http://localhost:8000/api/v1',
	reduxenv: 'local',
	websocketUrl: 'wss://ws1.atlasec.co/api/v1/ws'
}
*/
