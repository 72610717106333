import {createAction, handleActions} from 'redux-actions'

import {STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from 'constants'

import { getLocations, getMPoints } from "lib/api";
import { setGWErr, setGWStatus, setSensors } from "./gateway";

const initialState = {
  status: STATUS_NONE,
  error: '',
  locations: []
}

export const setLocations = createAction('SET_LOCATIONS')
export const setLocationStatus = createAction('SET_LOCATION_STATUS')
export const setLocationError = createAction('SET_LOCATION_ERROR')


export default handleActions({
  RESET_LOCATIONS: () => (initialState),
  SET_LOCATIONS: (state, action) => ({ ...state, locations: action.payload }),
  SET_LOCATION_STATUS: (state, action) => ({ ...state, status: action.payload }),
  SET_LOCATION_ERROR: (state, action) => ({...state, error: action.payload}),
}, initialState)



export const handleGetLocations = (organization_id) => {
  return function (dispatch, getState) {
    const { userOrgID } = getState().app
    dispatch(setLocationError(''))
    dispatch(setLocationStatus(STATUS_LOADING))
    getLocations({org_id: organization_id })
      .then(resp => {
        if (resp.ok) {
          dispatch(setLocations(resp.data))
          dispatch(setLocationStatus(STATUS_SUCCESS))
        }
        else {
          dispatch(setLocationError(resp.data))
          dispatch(setLocationStatus(STATUS_ERROR))
        }
      })
  }
}
