// Chakra imports
import {
  Box, Button,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
import moment from 'moment'
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import BarChart from "components/Charts/BarChart";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,StatsIcon
} from "components/Icons/Icons.js";
import {AtSignIcon, RepeatIcon} from '@chakra-ui/icons'

import { MdKeyboardBackspace } from "react-icons/md"

import React, { useEffect, useState } from "react";
import { dashboardTableData, timelineData } from "variables/general";
import TemperatureLevel from "./components/TemperatureLevel";
import EnergyConsumption from "./components/EnergyConsumption";
import MiniStatistics from "./components/MiniStatistics";
import InvoiceOverview from "./components/InvoiceOverview";
import RecordLogs from "./components/RecordLogs";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGetEmeterConsumptionHistory,
  handleGetGateways,
  handleGetGatewaySensorData,
  handleGetGatewaySensors
} from "redux/gateway";
import { chartTimeSeriesDataReducer } from 'lib/reducer';
import LineChart from "components/Charts/LineChart";
import { useLocation, useHistory } from "react-router-dom";
import HumidityLevel from "./components/HumidityLevel";
import HumanActivityLevel from "./components/HumanActivityLevel";
import AlertHistory from "./components/AlertHistory";


export default function Dashboard() {
  const iconBoxInside = useColorModeValue("white", "white");

  const dispatch = useDispatch()

  const domPageLocation = useLocation()
  const history = useHistory();


  const loggedIn =  useSelector(state => state.app.loggedIn)
  const accessToken = useSelector(state => state.app.token)
  const orgId = useSelector(state => state.app.userOrgID)
  const gateways = useSelector(state => state.gateway.gateways)
  const sensors = useSelector(state => state.gateway.sensors)
  const sensorData = useSelector(state => state.gateway.sensor_data)
  //const [sensorDataLoaded, setSensorDataLoaded] = useState(false) // 15 minutes

  const [locationId, setLocationId] = useState()
  const [locationName, setLocationName] = useState()
  const [measurementPoint, setMeasurementPoint] = useState()

  const [groupDataPeriod, setGroupDataPeriod] = useState(60) // 15 minutes

  const [energyType, setEnergyType] = useState("Watt-hour")
  const [energyMetric, setEnergyMetric] = useState('kWh' )
  const [energyDataMetric, setEnergyDataMetric] = useState('Wh' )
  const [unitEnergyPrice, setUnitEnergyPrice] = useState(2.4)

  const [deviceInfo, setDeviceInfo] = useState({})
  const [dataSensorMac, setDataSensorMac] = useState("")

  useEffect( () => {
    if(domPageLocation.params !== undefined) {
      setLocationId(domPageLocation.params.location_id)
      setLocationName(domPageLocation.params.locationName)
      let mpoint = domPageLocation.params.measurementPoint
      setMeasurementPoint(mpoint)

      if(orgId == 4) { setUnitEnergyPrice(2.4) ; setEnergyMetric('kWh') }
      if(orgId == 5) { setUnitEnergyPrice(4.45); setEnergyType('kiloWatt-hour');  setEnergyMetric('kWh') }
      if(orgId == 6) { setUnitEnergyPrice(3.9); setEnergyType('kiloWatt-hour');  setEnergyMetric('kWh') }

      //dispatch(handleGetGateways(orgId, domPageLocation.params.location_id))

      let _device_info = {}
      _device_info["GatewayName"] = mpoint.gateway.name
      _device_info["SensorName"] = mpoint.name
      _device_info["GatewayMac"] = mpoint.gateway.mac
      setDeviceInfo(_device_info)
      setDataSensorMac(mpoint.sensor.mac)
      let date_end = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      let date_start = moment().utc().subtract(7, 'd').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      let date_start_emeter_history = moment().utc().subtract(90, 'd').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      dispatch(handleGetGatewaySensorData(mpoint.id, date_start, date_end))
      dispatch(handleGetEmeterConsumptionHistory(mpoint.id, date_start_emeter_history, date_end))

    }
  }, [domPageLocation])
/*
  useEffect(()=> {
    if(gateways.results != undefined && gateways.results.length > 0) {
      let _device_info = {}
      _device_info["GatewayName"] = gateways.results[0].name
      _device_info["GatewayMac"] = gateways.results[0].mac
      setDeviceInfo(_device_info)
      dispatch(handleGetGatewaySensors(gateways.results[0].id))
    }
  }, [gateways])
*/
  /*
  useEffect(() => {
    if(sensors.results != undefined && sensors.results.length > 0) {
      setDataSensorMac(sensors.results[0].mac)
      let date_end = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      let date_start = moment().utc().subtract(7, 'd').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      let date_start_emeter_history = moment().utc().subtract(90, 'd').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      dispatch(handleGetGatewaySensorData(sensors.results[0].mac, date_start, date_end))
      dispatch(handleGetEmeterConsumptionHistory(sensors.results[0].mac, date_start_emeter_history, date_end))
    }
  }, [sensors])
  */

  return (
    <Flex flexDirection='column' pt={{ base: "500px", md: "75px" }}>
      <Box mb={"15px"}><Button  onClick={history.goBack}  variant="solid" size="lg"><MdKeyboardBackspace/> Back to list</Button></Box>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>

        <MiniStatistics
          title={"Location: "}
          description={locationName + " - " + deviceInfo["SensorName"]}
          amount={"$53,000"}
          percentage={55}
          icon={<AtSignIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Gateway ID:"}
          device_mac={deviceInfo["GatewayMac"]}
          amount={"2,300"}
          percentage={5}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Energy Meter ID:"}
          device_mac={dataSensorMac}
          amount={"+3,020"}
          percentage={-14}
          icon={<StatsIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Data Period:"}
          device_mac={"15 Minutes"}
          percentage={8}
          icon={<RepeatIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>
      <Grid
        templateColumns={{ sm: "1fr", lg: (orgId == 4 ?  "1.3fr 1.7fr" :"1fr" ) }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px' mt="20px"
        mb={{ lg: "26px" }}>
        {orgId != 7  ?
        <EnergyConsumption
          title={"Energy Consumption"}
          energyType={energyType}
          measurementPointID={measurementPoint?.id}
        /> : null }
        {orgId == 4 || orgId == 7 ?
          <TemperatureLevel
            title={"Temperature Level"}
          /> : null
        }
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        gap='24px' my='26px'>
        {orgId == 7 ?
          <HumidityLevel  title={"Humidity Level"} />
            : null }
        {orgId == 7 ?
          <HumanActivityLevel  title={"Activity Level"} />
          : null }
        {orgId == 7 ?
          <AlertHistory  title={"Alert History"} />
          : null }
        {orgId != 7  ?
        <RecordLogs
          title={"Data History"}
          amount={30}
          captions={["Date", "Consumed Energy", " ", "Percent in Monthly Consumption"]}
          energyMetric={energyMetric}
          dataMetric={energyDataMetric}
        /> : null }
        {orgId != 7  ?
        <InvoiceOverview
          title={"Invoice Overview"}
          energyMetric={energyMetric}
          dataMetric={energyDataMetric}
          currency="TL"
          unitEnergyPrice={unitEnergyPrice}
        /> : null }
      </Grid>



    </Flex>
  );
}
