import React, { useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  Stack,
  Text,
  Heading,
  Button,
  Collapse,
  HStack,
  VStack,
  Select,
  CircularProgress,
  CircularProgressLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorMode,
  useColorModeValue,
  IconButton,
  Progress,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import PDFViewer from './PDFViewer.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const data = [
  { name: '11/11/2023', value: 10 },
  { name: '11/12/2023', value: 15 },
  { name: '11/13/2023', value: 13 },
  { name: '11/14/2023', value: 17 },
  { name: '11/15/2023', value: 20 },
  { name: '11/16/2023', value: 22 },
  { name: '11/17/2023', value: 25 },
];
const temperatureData = {
  labels: ['23°C', '24°C', '25°C', '26°C', '27°C'],
  datasets: [
    {
      label: null, // Label kısmı boş bırakıldı
      data: [45, 75, 55, 47, 46],
      backgroundColor: [
        'rgba(75, 192, 192, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(153, 102, 255, 0.6)',
        'rgba(255, 99, 132, 0.6)',
      ],
    },
  ],
};

const FloorUtilization = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isMenuOpen, setIsMenuOpen] = useState(true);


  const handleLogoClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItemBg = useColorModeValue('transparent', 'gray.700');
  const menuItemHoverBg = useColorModeValue('blue.100', 'blue.800');
  const menuItemActiveBg = useColorModeValue('blue.200', 'blue.900');
  const logoFilter = useColorModeValue('none', 'invert(1)');

  return (
    <Grid
      templateColumns={isMenuOpen ? '100% 1fr' : '0 1fr'}
      gap={12}
      bg={useColorModeValue('gray.100', 'gray.900')}
      color={useColorModeValue('gray.800', 'gray.200')}
      height="100vh"
    >

      {/* İçerik Bölgesi */}
      <Box p={12} flex="1" bg={useColorModeValue('white', 'gray.900')}>
        <Flex justify="space-between" mb={12} alignItems="center">
          <Flex alignItems="center">
            {!isMenuOpen && (
              <Button variant="ghost" mb={4} onClick={handleLogoClick}>
                <Image
                  src={atlasecLogo}
                  alt="ATLASEC Logo"
                  width={150}
                  height={50}
                  style={{ filter: logoFilter }}
                />
              </Button>
            )}
          </Flex>

        </Flex>

        {/* Dashboard İçerik Bölgesi */}
        <Box p={4}>
          <Flex justifyContent="space-between" mb={4}>
            <Heading size="md">Environment</Heading>
            <HStack spacing={2}>
              <Select placeholder="Select date" size="sm">
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last7days">Last 7 days</option>
              </Select>
              <Select placeholder="Select floor" size="sm">
                <option value="all">All floors</option>
                <option value="floor1">Floor 1</option>
                <option value="floor2">Floor 2</option>
              </Select>
            </HStack>
          </Flex>

          <Flex>
            <Box flex={2} mr={4}>
              <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                <Heading size="sm" mb={2}>Utilization map - Details</Heading>
                <div style={{ maxWidth: '100%', height: '590px', marginTop:0, overflow: 'hidden', position: 'relative' }}>
                  <PDFViewer />
                </div>
              </Box>

              <Box borderWidth={1} borderRadius="lg" p={4}>
                <Heading size="sm" mb={2}>Overall utilization trend</Heading>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <RechartsTooltip />
                    <Line type="monotone" dataKey="value" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Box>

            <Box flex={1}>
              <VStack spacing={4} align="stretch">
                <Box textAlign="center" p={4} borderWidth={1} borderRadius="lg">
                  <Text fontSize="xl">Desks</Text>
                  <Text fontSize="4xl" fontWeight="bold">165</Text>
                  <CircularProgress value={19.5} color="green.500" size="100px" thickness="10px">
                    <CircularProgressLabel>19.5%</CircularProgressLabel>
                  </CircularProgress>
                  <Text mt={2} fontSize="sm"></Text>
                </Box>

                {/* Temperature  verileri */}
                <Box textAlign="center" p={4} borderWidth={1} borderRadius="lg" mt={4}>
                  <Text fontSize="xl">Temperature </Text>
                  <Text fontSize="4xl" fontWeight="bold">24.2 C</Text>
                  <Progress value={40} colorScheme="yellow" size="lg" hasStripe isAnimated />
                  <Text mt={2} fontSize="sm"></Text>
                </Box>

                {/* Humidity verileri */}
                <Box textAlign="center" p={4} borderWidth={1} borderRadius="lg" mt={4}>
                  <Text fontSize="xl">Humidity</Text>
                  <Text fontSize="4xl" fontWeight="bold">40%</Text>
                  <Progress value={40} colorScheme="blue" size="lg" hasStripe isAnimated />
                  <Text mt={2} fontSize="sm"></Text>
                </Box>

                {/* Seat Occupancy by Room Temperature BarChart */}
                <Box textAlign="center" p={4} borderWidth={1} borderRadius="lg" mt={4}>
                  <Text fontSize="xl">Seat Occupancy by Room Temperature</Text>
                  <Bar
                    data={temperatureData}
                    options={{
                      responsive: true,
                      scales: {
                        y: {
                          min: 0,  // Y ekseni için minimum değeri 0 yap
                          max: 100, // Y ekseni için maksimum değeri 100 yap
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                          position: 'top',
                        },
                        title: {
                          display: false,
                        },
                      },
                    }}
                  />
                </Box>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Grid>
  );
};
//  <!--<PDFViewer />-->
export default FloorUtilization;

