import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { STATUS_NONE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'constants'
import {handleLogin, setLoginStatus} from "redux/app";

// Chakra imports
import {
  Box,
  Flex,
  Button,
  AbsoluteCenter,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  Spinner,
  ChakraProvider,
  useColorModeValue,
} from "@chakra-ui/react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Assets
import signInImage from "assets/img/signInImage.png";
import { AtlasecIcon } from "components/Icons/Icons";
import { Separator } from "components/Separator/Separator";
import theme from "../../theme/theme";

const SignIn = () =>  {

  const titleColor = useColorModeValue("blue.700", "gray.200");
  const textColor = useColorModeValue("gray.400", "white");

  const dispatch = useDispatch()

  const [apiStatus, setStatus] = useState(STATUS_NONE)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMeActive, setRememberMeActive] = useState(false)

  const loginStatus = useSelector(state => state.app.loginStatus)
  const loginError = useSelector(state => state.app.loginErr)
  const loggedIn = useSelector(state => state.app.loggedIn)
  const user = useSelector(state => state.app.loggedInUser)

  const loginAction = () => {
    dispatch(handleLogin(email, password))
  }

  useEffect(() => {
    //console.log("LOGIN ", loginStatus)
    switch(loginStatus) {
      case STATUS_LOADING:
        setLoading(true)
        break;
      case STATUS_SUCCESS:
        setLoading(false)
        toast.success("Login Successfull")
        break;
      case STATUS_ERROR:
        setLoading(false)
        toast.error(loginError)
        setLoginStatus(STATUS_NONE)
        break;
    }
  }, [loginStatus])


  // Chakra color mode
  return (
    <ChakraProvider theme={theme} resetCss={false} w='100%'>
      <ToastContainer position="top-center"
                      autoClose={3000}
      />

    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='center'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}

          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='gray.50'
            p='48px'
            border="1px"
            borderColor="gray.300"
            borderRadius="40px"
            justifyContent="center"
            mt={{ md: "150px", lg: "80px" }}>
            <Heading  mb='10px' ml='40px'>

              <AtlasecIcon w="240px" h="46px" me="10px" ml="10px" mb="10px" />

              <Text ml="85px" mb="10px" color={titleColor} fontWeight="normal" fontSize='20px' >
                HUB Login
              </Text>
            </Heading>
            <Separator mb="20px">
            </Separator>
            {loading ?
              <Box position='relative' h='40px'>
                <AbsoluteCenter>
                  <Spinner  mt={"0px"}  mb={"10px"}
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='lg'
                  /></AbsoluteCenter> </Box>: null }
            <FormControl>
              <Input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Your email adress'
                size='lg'
              />
              <Input
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                borderRadius='15px'
                mb='36px'
                fontSize='sm'
                type='password'
                placeholder='Your password'
                size='lg'
              />
              {rememberMeActive ?
              <FormControl display='flex' alignItems='center'>
                <Switch id='remember-login' colorScheme='teal' me='10px' />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  ms='1'
                  fontWeight='normal'>
                  Remember me
                </FormLabel>
              </FormControl>
                : null }
              <Button
                onClick={loginAction}
                fontSize='10px'
                type='submit'
                bg='blue.400'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: "blue.200",
                }}
                _active={{
                  bg: "blue.400",
                }}>
                SIGN IN
              </Button>
            </FormControl>

          </Flex>
        </Flex>

      </Flex>
    </Flex>
    </ChakraProvider>
  );
}

export default SignIn
