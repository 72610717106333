import React, { useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';

// Worker dosyasının yolunu ayarlama (mjs dosyası için)
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'; // Eğer bu dosya public klasöründeyse

const PDFViewer = () => {
  const canvasRef = useRef(null); // Canvas referansı

  useEffect(() => {
    const renderPDF = async () => {
      const url = '/20120327_Haus3OG01.pdf'; // PDF dosyasının yolu
      const loadingTask = pdfjsLib.getDocument(url); // PDF dosyasını yükle
      const pdf = await loadingTask.promise; // PDF'yi bekle
      const page = await pdf.getPage(1); // İlk sayfayı al

      const canvas = canvasRef.current; // Canvas referansını al
      const context = canvas.getContext('2d'); // 2D bağlamı al
      const viewport = page.getViewport({ scale: 0.65 }); // Görüntüleme ölçeğini ayarla

      // Canvas boyutunu ayarla
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context, // Canvas bağlamı
        viewport: viewport, // Görüntüleme alanı
      };
      await page.render(renderContext); // Sayfayı canvas'a render et
    };

    renderPDF(); // PDF render işlemini başlat
  }, []);

  return (
    <div style={{ perspective: '600px', marginLeft: '10px' }}>
      <canvas
        ref={canvasRef} // Canvas referansını ata
        style={{
          transform: 'rotateX(30deg) rotateZ(-15deg)', // 3D dönüşüm efektleri
          boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.3)',
        }}
      />
    </div>
  );
};

export default PDFViewer;
