import React, { Component, useEffect, useReducer, useState } from "react";
import Card from "components/Card/Card";
import Chart from "react-apexcharts";
import { barChartOptions } from "variables/charts";

const BarChart = ({data, startDate, endDate}) => {

  const [chartData , setChartData] = useState({
    options: barChartOptions,
    series: [data]
  })

  const [display, setDisplay] = useState(false);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    chartData.series = [data] // ALWAYS ARRAY ALWAYSSSSS
    setChartData(chartData)
    setTimeout(() => forceUpdate(), 100);
  }, [data])

    return (
      <Card
        py="1rem"
        height={{ sm: "200px" }}
        width="100%"
        bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
        position="relative"
      >
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          width="100%"
          height="100%"
        />
      </Card>
    );

}

export default BarChart;
